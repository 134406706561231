import React from 'react'
import { useAuth0 } from '../utils/auth'

import { faBacon } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function LoggingIn(props) {
  // const { isAuthenticated, user } = useAuth0()
  const { isAuthenticated } = useAuth0()

  return !isAuthenticated && (
    <section id="logging-page" className='c0 text-center flex-12 gap-075 v-center ai-center'>
      <div className="span-12 span-12-mobile padd-2 text-center flex ai-center jc-center v-center" style={{minHeight: 'calc(100vh - 15em)'}}>
        <div>
          <h6>Logging in...</h6>
          <div className="loading"><div className="loader-spinner"><FontAwesomeIcon icon={faBacon} /></div></div> 
          <p>Please wait</p>
        </div>
      </div>
    </section>
  )
}

export default LoggingIn
